/* --------------------------------------------------------------------
Toggles the aria-expanded attribute of the provided element
-------------------------------------------------------------------- */
export function toggleAriaExpanded( ele ){
    if( ele ){
        let isExpanded = JSON.parse(ele.getAttribute('aria-expanded'));
        let x = null;
        x = (isExpanded) ? false : true;

        ele.setAttribute('aria-expanded', x)
    }
}

export function addTabIndex( cls ){
    let elements = document.getElementsByClassName( cls );

    if (elements) {
        for (var i = elements.length - 1; i >= 0; i--) {
           if( !elements[i].hasAttribute('tabindex') ){
                elements[i].setAttribute('tabindex', '-1')
                elements[i].setAttribute('disabled', 'disabled');
           }
       };
   }
}

export function removeTabIndex( cls ){
    let elements = document.getElementsByClassName( cls );

    if (elements) {
       for (var i = elements.length - 1; i >= 0; i--) {
           if( elements[i].hasAttribute('tabindex') ){
                elements[i].removeAttribute('tabindex');
                elements[i].removeAttribute('disabled');
           }
       };
    }

}